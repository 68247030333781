<template>
  <div
    class="mx-6 p-6 border rounded"
    :class="
      themeEnabled
        ? 'text-black bg-gray-100 border-gray-200'
        : 'text-white bg-white/5 border-white/5'
    "
  >
    <GiftIcon class="w-4 h-4" />
    <p class="mt-1 text-sm">
      {{ $t("gifts.looking_for_sth_custom") }}
    </p>
    <ActionLink
      is-external-link
      :href="customizationAdCTALink"
      class="block mt-1 text-sm"
      target="_blank"
      @click="trackCustomizationAdClick"
    >
      {{ $t("gifts.customization_cta") }}
    </ActionLink>
  </div>
</template>

<script setup>
import { GiftIcon } from "@heroicons/vue/20/solid"
import { usePageProps } from "@/composables/pageProps"
import captureUserEvent from "@/helpers/captureUserEvent"
import ActionLink from "@/components/elements/links/ActionLink.vue"

defineProps({
  customizationAdCTALink: String,
})

const { themeEnabled } = usePageProps()

const trackCustomizationAdClick = () => {
  captureUserEvent("General - Request custom clicked", {
    banner_type: "menu",
  })
}
</script>
